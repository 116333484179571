import React, {useState, useEffect, useContext} from 'react'
import {connect} from 'react-redux'

import {SocketContext} from '../../context/socket'
import Arena from '../Arena'

import './SpinningWheel.scss'
import Button from "../Button";

const SpinningWheel = (props) => {

    const [counter, setCounter] = useState(props.automaticWheelSpin)
    const [selectedItem, setSelectedItem] = useState(null)
    const [spinning, setSpinning] = useState('')
    const [rotation, setRotation] = useState(`0deg`)

    const socket = useContext(SocketContext)

    const wheelVars = {
        '--nb-item': props.wheelItems.length,
        '--selected-item': selectedItem,
        '--rotation': rotation
    }

    useEffect(() => {
        if (props.automaticWheelSpin === 0) return
        const interval = setInterval(() => {
            if (counter === 0) {
                setCounter(0)
                return clearInterval(interval)
            }
            setCounter(counter - 1)
        }, 1000)
        return () => clearInterval(interval)
    })

    useEffect(() => {
        setSelectedItem(props.wheelItem)
    }, [props.wheelItem])

    useEffect(() => {
        setRotation(`${props.wheelRotation}deg`)
        setSpinning('spinning')

    }, [props.wheelRotation])

    /**
     * Spin the wheel
     */

    const spinTheWheel = () => {
        if (!props.canSpinTheWheel) return
        socket.emit('spin_the_wheel')
    }

    return (
        <div className="wheel-outer">
            <div className="wheel-inner">
                <div className="wheel-container">
                    <div className="wheel-arrow"/>
                    <div className="wheel-cards">
                        <Arena withVs={true} smallCards={true}/>
                    </div>
                    <div className={`wheel ${spinning}`} style={wheelVars}>
                        {props.wheelItems.map((item, index) => (
                            <div className={`wheel-item ${item.emoji ? 'emoji' : ''}`} key={index}
                                 style={{'--item-nb': index}}>
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="wheel-button">
                    {props.canSpinTheWheel ?
                        !props.spinTheWheel ?
                            <Button className="blue" onClick={spinTheWheel}>SPIN</Button> :
                            <Button className="blue disabled" onClick={spinTheWheel}>Spinning</Button> :
                        <div className="wheel-message">
                            <p>Oh no! Not the garlic!</p>
                            <p>Nobody likes that foul smell.</p>
                        </div>
                    }
                </div>
                <div
                    className="wheel-status">{props.canSpinTheWheel ? 'Which attribute shall decide your fate?' : 'Your opponent spins...'}</div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    automaticWheelSpin: state.play.automaticWheelSpin,
    canSpinTheWheel: state.play.canSpinTheWheel,
    spinTheWheel: state.play.spinTheWheel,
    spinningTime: state.play.spinningTime,
    wheelItems: state.play.wheelItems,
    wheelRotation: state.play.wheelRotation,
    wheelItem: state.play.wheelItem
})

export default connect(mapStateToProps)(SpinningWheel)
