import React, {useContext} from 'react'
import {connect} from 'react-redux'
import MatchHistory from "./MatchHistory";
import Button from "../Button";
import {SocketContext} from "../../context/socket";

import './GameResults.scss'
import {hideGameResults, showCollection} from '../../store/actions'

const GameResults = (props) => {

    const socket = useContext(SocketContext)

    const getLeaderboard = () => {
        socket.emit('get_leaderboard')
    }

    const playAgain = () => {
        props.dispatch(hideGameResults())
        socket.emit('ready', props.deck.deck_id)
    }

    const goHome = () => {
        props.dispatch(showCollection())
    }

    return (
        <div id="game-results">
            <div className="game-results-container">
                <div className="results">
                    {props.gameResults.map((game, index) => (
                        <MatchHistory
                            round={index}
                            current={game.current}
                            opponent={game.opponent}
                            wheelItem={game.wheelItem}
                        />
                    ))}
                </div>
                <Button className="blue play-again" onClick={playAgain}>Play again</Button>
                <Button className="simple home" onClick={goHome}>Home</Button>
                <Button className="simple leaderboard" onClick={getLeaderboard}>Leaderboard</Button>
                <div className="winner">{`${props.isMatchWinner ? props.username : props.opponentUsername} won!`}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    gameResults: state.play.gameResults,
    deck: state.play.deck,
    winner: state.play.isMatchWinner,
    username: state.play.username,
    opponentUsername: state.play.opponentUsername
})

export default connect(mapStateToProps)(GameResults)
