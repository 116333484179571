import React from 'react'
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import ReactDOM from 'react-dom'

import Playground from './game/Playground'

import reducers from './store/reducers'
import reportWebVitals from './reportWebVitals'

import './index.scss'
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <Playground/>
            </DndProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
