import React from 'react'
import {connect} from 'react-redux'

import Card from './Card'

const Arena = (props) => {

    const currentCard = {
        arena: true,
        label: "You",
        empty: !props.playingCard && !props.current,
        vampire: props.current || props.playingCard,
        smallCards: props.smallCards,
        extended: props.extended,
        winner: props.current ? props.current.isMatchWinner : (props.isMatchWinner && !props.tie)
    }

    const opponentCard = {
        arena: true,
        label: "Opponent",
        empty: !props.opponentCard && !props.opponent,
        vampire: props.opponent || props.opponentCard,
        smallCards: props.smallCards,
        extended: props.extended,
        winner: props.opponent ? props.opponent.isMatchWinner : (!props.isMatchWinner && !props.tie)
    }

    return (
        <div className="arena">
            <div className="playing-cards">
                <Card {...currentCard} singleAttribute={props.singleAttribute}/>
                {props.withVs && <div className="vs">vs</div>}
                <Card {...opponentCard} singleAttribute={props.singleAttribute}/>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    playingCard: state.play.playingCard,
    opponentCard: state.play.opponentCard,
    tie: state.play.isTie,
    gameResults: state.play.gameResults
})

export default connect(mapStateToProps)(Arena)
