import {
    PICK_CARD,
    OPPONENT_PICKED_CARD,
    WHEEL_VISIBILITY,
    PRESTART_MATCH,
    START_MATCH,
    STOP_MATCH,
    STORE_GAME_DECK,
    SPIN_WHEEL,
    SET_WHEEL_ITEM,
    SET_WHEEL_ROTATION,
    SHOW_MATCH_RESULTS,
    STORE_WHEEL_ITEMS,
    SET_TIME_BETWEEN_MATCHES,
    SET_AUTOMATIC_WHEEL_SPIN,
    SHOW_GAME_RESULTS,
    DECREASE_OPPONENT_CARDS,
    CHECK_USERNAME,
    SHOW_COLLECTION,
    SHOW_NEW_DECK,
    HIDE_NEW_DECK,
    REMOVE_CARD_FROM_DECK,
    CHANGE_DECK_NAME,
    SET_WALLET_DATA,
    SET_USERNAME,
    SET_DECKS,
    SET_WALLET_ADDRESS,
    STORE_EXTENDED_DECK,
    DECK_SAVED,
    DELETE_DECK,
    INSERT_IN_DECK,
    SET_EXTENDED_DECK_ITEMS,
    DISABLE_ROOM,
    SHOW_LEADERBOARD,
    HIDE_LEADERBOARD,
    HIDE_GAME_RESULTS,
    SHOW_WELCOME,
    HIDE_MY_COLLECTION_INTRO,
    HIDE_DECK_MANAGER_INTRO,
    RESET_NEW_DECK,
    STORE_NEW_DECK,
    STORE_TOTAL_PLAYERS_ONLINE, SET_OPPONENT_USERNAME
} from '../actions/actionTypes'

const initialState = {
    username: false,
    playingCard: false,
    opponentCards: 5,
    totalPlayers: 0,
    opponentCard: false,
    prestartMatch: false,
    wheelVisible: false,
    canSpinTheWheel: false,
    timeBeforeMatch: 0,
    matchTime: 0,
    startMatch: false,
    spinTheWheel: false,
    spinningTime: 0,
    wheelItem: null,
    wheelRotation: 0,
    isWinner: false,
    isTie: false,
    showMatchResults: false,
    timeBetweenMatches: 0,
    automaticWheelSpin: 0,
    wheelItems: [],
    isLastMatch: false,
    showGameResults: false,
    showLeaderboard: false,
    newDeck: {
        name: null,
        items: [false, false, false, false, false]
    },
    extendedDeck: null,
    deck: null,
    decks: [],
    gameResults: [],
    checkingUsername: false,
    collection: null,
    showCollection: false,
    showNewDeck: false,
    walletAddress: null,
    positionInLeaderboard: 0,
    showWelcome: true,
    hideMyCollectionIntro: false,
    hideDeckManagerIntro: false,
    opponentUsername: null
}

const play = (state = initialState, action) => {

    const {
        type,
        vampire,
        username,
        timeBeforeMatch,
        matchTime,
        deck,
        wheelVisible,
        canSpinTheWheel,
        spinningTime,
        wheelItem,
        wheelRotation,
        isMatchWinner,
        isTie,
        wheelItems,
        timeBetweenMatches,
        automaticWheelSpin,
        isLastMatch,
        gameResults,
        collection,
        card,
        name,
        decks,
        index,
        walletAddress,
        extendedDeckItems,
        leaderboard,
        positionInLeaderboard,
        players,
        opponentUsername
    } = action

    switch (type) {
        case STORE_TOTAL_PLAYERS_ONLINE:
            return {
                ...state,
                totalPlayers: players
            }
        case SHOW_COLLECTION:
            return {
                ...state,
                showCollection: true,
                showGameResults: false,
                showWelcome: false,
            }
        case SHOW_WELCOME:
            return {
                ...state,
                showWelcome: true,
            }
        case SHOW_NEW_DECK:
            return {
                ...state,
                showNewDeck: true,
                showCollection: false
            }
        case STORE_NEW_DECK:
            return {
                ...state,
                newDeck: deck
            }
        case HIDE_NEW_DECK:
            return {
                ...state,
                showNewDeck: false,
            }
        case CHANGE_DECK_NAME:
            return {
                ...state,
                newDeck: {
                    ...state.newDeck,
                    name
                }
            }
        case INSERT_IN_DECK:
            return {
                ...state,
                newDeck: {
                    ...state.newDeck,
                    items: [...state.newDeck.items.slice(0, index), card, ...state.newDeck.items.slice(index + 1)]
                }
            }
        case RESET_NEW_DECK:
            return {
                ...state,
                newDeck: initialState.newDeck
            }
        case REMOVE_CARD_FROM_DECK:
            return {
                ...state,
                newDeck: {
                    ...state.newDeck,
                    items: [...state.newDeck.items.slice(0, index), false, ...state.newDeck.items.slice(index + 1)]
                }
            }
        case PICK_CARD:
            return {
                ...state,
                playingCard: vampire
            }
        case OPPONENT_PICKED_CARD:
            return {
                ...state,
                opponentCard: vampire,
            }
        case PRESTART_MATCH:
            return {
                ...state,
                timeBeforeMatch,
                prestartMatch: true,
                startMatch: false,
                playingCard: false,
                canSpinTheWheel: false,
                wheelVisible: false,
                opponentCard: false,
                spinTheWheel: false,
                showMatchResults: false,
                showGameResults: false,
                spinningTime: 0,
                wheelRotation: 0,
                gameResults: []
            }
        case START_MATCH:
            return {
                ...state,
                matchTime,
                showMatchResults: false,
                startMatch: true,
                isWinner: false,
                isTie: false,
                prestartMatch: false,
                opponentUsername: null
            }
        case STOP_MATCH:
            return {
                ...state,
                startMatch: false,
                prestartMatch: false,
                playingCard: false,
                // canSpinTheWheel: false,
                wheelVisible: false,
                showMatchResults: false,
                wheelItem: null,
                wheelRotation: 0,
                opponentCard: false,
                spinTheWheel: false,
                isWinner: false,
                isTie: false,
                opponentCards: 5,
                spinningTime: 0
            }
        case WHEEL_VISIBILITY:
            return {
                ...state,
                wheelVisible,
                canSpinTheWheel
            }
        case SPIN_WHEEL:
            return {
                ...state,
                spinTheWheel: true,
                spinningTime
            }
        case STORE_GAME_DECK:
            return {
                ...state,
                deck
            }
        case STORE_EXTENDED_DECK:
            return {
                ...state,
                extendedDeck: deck,
                showCollection: false,
            }
        case DELETE_DECK:
            return {
                ...state,
                decks: state.decks.filter(item => item !== deck)
            }
        case SET_WHEEL_ITEM:
            return {
                ...state,
                wheelItem
            }
        case SET_WHEEL_ROTATION:
            return {
                ...state,
                wheelRotation
            }
        case SHOW_MATCH_RESULTS:
            return {
                ...state,
                showMatchResults: true,
                isMatchWinner,
                isTie,
                isLastMatch,
                gameResults
            }
        case STORE_WHEEL_ITEMS:
            return {
                ...state,
                wheelItems
            }
        case SET_TIME_BETWEEN_MATCHES:
            return {
                ...state,
                timeBetweenMatches
            }
        case SET_AUTOMATIC_WHEEL_SPIN:
            return {
                ...state,
                automaticWheelSpin
            }
        case SHOW_GAME_RESULTS:
            return {
                ...state,
                showMatchResults: false,
                showGameResults: true,
                wheelVisible: false,
                startMatch: false
            }
        case HIDE_GAME_RESULTS:
            return {
                ...state,
                showGameResults: false
            }
        case DECREASE_OPPONENT_CARDS:
            return {
                ...state,
                opponentCards: state.opponentCards - 1
            }
        case CHECK_USERNAME:
            return {
                ...state,
                checkingUsername: true
            }
        case SET_WALLET_DATA:
            return {
                ...state,
                collection,
                username,
                decks,
                checkingUsername: false
            }
        case SET_USERNAME:
            return {
                ...state,
                username
            }
        case SET_DECKS:
            return {
                ...state,
                decks
            }
        case DECK_SAVED:
            return {
                ...state,
                showNewDeck: false,
                showCollection: true
            }
        case SET_WALLET_ADDRESS:
            return {
                ...state,
                walletAddress
            }
        case SET_EXTENDED_DECK_ITEMS:
            return {
                ...state,
                extendedDeck: {
                    ...state.deck,
                    items: extendedDeckItems
                }
            }
        case DISABLE_ROOM:
            return {
                ...state,
                extendedDeck: null,
                startMatch: false,
                showMatchResults: true
            }
        case SHOW_LEADERBOARD:
            return {
                ...state,
                showLeaderboard: true,
                positionInLeaderboard,
                leaderboard
            }
        case HIDE_LEADERBOARD:
            return {
                ...state,
                showLeaderboard: false
            }
        case HIDE_MY_COLLECTION_INTRO:
            return {
                ...state,
                hideMyCollectionIntro: true
            }
        case HIDE_DECK_MANAGER_INTRO:
            return {
                ...state,
                hideDeckManagerIntro: true
            }
        case SET_OPPONENT_USERNAME:
            return {
                ...state,
                opponentUsername
            }
        default:
            return state
    }

}

export default play
