import React, {Fragment, useEffect, useContext, useMemo} from 'react'
import {connect, useDispatch} from 'react-redux'

import {SocketContext} from '../context/socket'

import spin from '../assets/sounds/spin.wav'
/*import ost from '../assets/sounds/ost.mp3'*/

import {
    opponentPickedCard,
    hideMessages,
    prestartMatch,
    showLostMessage,
    showWinMessage,
    startMatch,
    stopMatch,
    storeGameDeck,
    wheelIsVisible,
    spinWheel,
    setWheelItem,
    showMatchResults,
    storeWheelItems,
    setTimeBetweenMatches,
    decreaseOpponentCards,
    setAutomaticWheelSpin,
    setWheelRotation,
    pickCard,
    setWalletData,
    setUsername,
    setDecks,
    deckSaved,
    storeExtendedDeck,
    setExtendedDeckItems,
    disableRoom,
    showLeaderboard,
    hideMyCollectionIntro,
    hideDeckManagerIntro,
    storeNewDeck,
    storeTotalPlayersOnline,
    setOpponentUsername
} from '../store/actions'

const Socket = (props) => {

    /*const ostAudio = new Audio(ost)*/
    const spinSound = useMemo(() => new Audio(spin), [])

    const dispatch = useDispatch()

    const socket = useContext(SocketContext)

    useEffect(() => {
        if (socket.connected && props.walletAddress) {
            let walletAddress = props.walletAddress
            socket.emit('get_wallet_data', walletAddress)
        }
    }, [socket, props.walletAddress])

    useEffect(() => {

        socket.on('connect', () => {
        })

        socket.on('disconnect', () => {
            dispatch(stopMatch())
        })

        socket.on('config', (event) => {
            event = JSON.parse(event)
            if (event.wheelItems) dispatch(storeWheelItems(event.wheelItems))
            if (event.timeBetweenMatches) dispatch(setTimeBetweenMatches(event.timeBetweenMatches))
            if (event.automaticWheelSpin) dispatch(setAutomaticWheelSpin(event.automaticWheelSpin))
        })

        socket.on('opponent_connected', (payload) => {
            console.log('opponent_connected')
        })


        socket.on('opponent_picked_card', () => {
            // dispatch(opponentPickedCard());
        })

        socket.on('decrease_opponent_cards', () => {
            dispatch(decreaseOpponentCards())
        })

        socket.on('prestart_match', (event) => {
            event = JSON.parse(event)
            // ostAudio.play()
            dispatch(hideMessages())
            dispatch(prestartMatch(event.time))
        })

        socket.on('start_match', (event) => {
            event = JSON.parse(event)
            dispatch(startMatch(event.matchTime))
        })

        socket.on('stop_match', () => {
            dispatch(stopMatch())
        })

        socket.on('end_match', (event) => {
            event = JSON.parse(event)
            dispatch(event.status === 'win' ? showWinMessage() : showLostMessage())
        })

        socket.on('game_deck', (deck) => {
            deck = JSON.parse(deck)
            dispatch(storeGameDeck(deck))
        })

        socket.on('show_wheel', (event) => {
            event = JSON.parse(event)
            dispatch(opponentPickedCard(event.opponent))
            dispatch(pickCard(event.vampire))
            dispatch(wheelIsVisible(event.spinner))
            dispatch(setOpponentUsername(event.opponentUsername))
        })

        socket.on('spin_wheel', (event) => {
            event = JSON.parse(event)
            dispatch(spinWheel(event.time))
            spinSound.play()
        })

        socket.on('spin_result', (event) => {
            event = JSON.parse(event)
            dispatch(setWheelItem(event.item))
        })

        socket.on('spin_rotation', (event) => {
            event = JSON.parse(event)
            dispatch(setWheelRotation(event.rotation))
        })

        socket.on('match_result', (event) => {
            event = JSON.parse(event)
            dispatch(showMatchResults(event.winner, event.tie, event.lastMatch, event.gameResults))
        })

        socket.on('new_match', () => {
            console.log('start new match')
        })

        socket.on('wallet_data', (walletData) => {

            walletData = JSON.parse(walletData)

            dispatch(setWalletData(walletData))

            if (walletData.hide_my_collection_intro)
                dispatch(hideMyCollectionIntro())

            if (walletData.hide_deck_manager_intro)
                dispatch(hideDeckManagerIntro())

        })

        socket.on('username', (username) => {
            dispatch(setUsername(username))
        })

        socket.on('deck_saved', (decks) => {
            dispatch(setDecks(JSON.parse(decks)))
            dispatch(deckSaved())
        })

        socket.on('deck', (deck) => {
            deck = JSON.parse(deck)
            dispatch(storeExtendedDeck(deck))
        })

        socket.on('totalPlayersOnline', (clients) => {
            dispatch(storeTotalPlayersOnline(clients))
        })

        socket.on('extended_deck', (ext_deck) => {
            ext_deck = JSON.parse(ext_deck)
            dispatch(storeNewDeck(ext_deck))
        })

        socket.on('extended_deck_items', (extendedDeckItems) => {
            dispatch(setExtendedDeckItems(JSON.parse(extendedDeckItems)))
        })

        socket.on('disable_room', () => {
            dispatch(disableRoom())
        })

        socket.on('show_leaderboard', (event) => {
            event = JSON.parse(event)
            dispatch(showLeaderboard(event.leaderboard, event.position))
        })

        return () => socket.close()

    }, [socket, dispatch, spinSound])

    return <Fragment/>
}

const mapStateToProps = (state) => ({
    walletAddress: state.play.walletAddress,
    play: state.play
})

export default connect(mapStateToProps)(Socket)
