import React from 'react'
import {connect, useDispatch} from 'react-redux'

import {hideLeaderboard} from '../../store/actions'

import './Leaderboard.scss'

const Leaderboard = (props) => {

    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(hideLeaderboard())
    }

    return (
        <div id="leaderboard">
            <div className="inner">
                <h2>In the memory of</h2>
                <span className="close" onClick={onClose}/>
                <div className="leaderboard-content">
                    <h1>Leader Board</h1>
                    <div className="players">
                        {props.leaderboard.length && props.leaderboard.map((player, key) => (
                            <div className="player" key={key}><span>{key + 1}.</span> {player.name}</div>
                        ))}
                    </div>
                    <div className="current-player"><span>{props.positionInLeaderboard}.</span> {props.username}</div>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    leaderboard: state.play.leaderboard,
    positionInLeaderboard: state.play.positionInLeaderboard,
    username: state.play.username
})

export default connect(mapStateToProps)(Leaderboard)
