import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {useDrag} from 'react-dnd'

import './Card.scss'

const Card = (props) => {

    const vampireTypes = {
        original: {
            Bg_1: 'Blood Knight',
            Bg_2: 'Green Knight',
            Bg_3: 'Purple Knight',
            Bg_4: 'Sky Knight',
            Bg_5: 'Laser Knight',
            Bg_6: 'Magic Knight',
            Bg_7: 'Orange Knight',
            Bg_8: 'Count Dracula'
        },
        halloween: {
            Bg_1: 'Acid Knight',
            Bg_2: 'Hell Knight',
            Bg_3: 'Spy Knight',
            Bg_4: 'Fire Knight'
        }
    }

    const classes = ['card']

    const getImage = () => {
        let vampireIndex = props.vampire.id
        if(props.vampire.halloween) vampireIndex -= 1
        return `/static/vampires/${props.vampire.halloween ? 'halloween/' : ''}${vampireIndex}.jpg`
    }

    const getType = () => {
        const vampireType = props.vampire.halloween ? 'halloween' : 'original'
        return vampireTypes[vampireType][props.vampire.bg.label]
    }

    const [{isDragging}, drag] = useDrag(() => ({
        type: 'card',
        item: {type: 'card', vampire: props.vampire},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    }))

    if (props.current) classes.push('current')
    if (props.singleAttribute && props.singleAttribute !== 'garlic') classes.push('single-attribute')
    if (props.smallCards) classes.push('small')
    if (props.arena) classes.push('arena')
    if (props.withHover) classes.push('with-hover')
    if (props.className) classes.push(props.className)
    if (props.draggable) classes.push('draggable')
    if (props.withZoom) classes.push('with-zoom')
    if (isDragging) classes.push('dragging')

    let content = <span/>

    if (props.label) content = <span>{props.label}</span>

    if (props.vampire && props.vampire !== 'back')
        content =
            <Fragment>
                <div className="background"/>
                <div className="image">
                    <img src={getImage()} alt=""/>
                </div>
                <div className="statistics">
                    <div className="score">{props.vampire.stripes.amount}</div>
                    <div className="title">{getType()}</div>
                    {props.singleAttribute && props.singleAttribute !== 'garlic' ? <div className="single-prop">
                            {props.winner && <div className="star"/>}
                            <div className="prop">
                                <div className={`prop-icon ${props.singleAttribute}`}/>
                                <div className="value">{props.vampire[props.singleAttribute].amount}</div>
                            </div>
                        </div> :
                        <div className="extended-props">
                            <div className="prop bg-prop">
                                <div className="prop-icon bg"/>
                                <div className="value">{props.vampire.bg.amount}</div>
                            </div>
                            <div className="prop eyes-prop">
                                <div className="prop-icon eyes"/>
                                <div className="value">{props.vampire.eyes.amount}</div>

                            </div>
                            <div className="prop clothes-prop">
                                <div className="prop-icon clothes"/>
                                <div className="value">{props.vampire.clothes.amount}</div>

                            </div>
                            <div className="prop mouth-prop">
                                <div className="prop-icon mouth"/>
                                <div className="value">{props.vampire.mouth.amount}</div>

                            </div>
                            {/*<div className="prop">
                            <div className="label">cape</div>
                            <div className="value">{props.vampire.cape.amount}</div>
                        </div>*/}
                            <div className="prop weapon-prop">
                                <div className="prop-icon weapon"/>
                                <div className="value">{props.vampire.weapon.amount}</div>

                            </div>
                            <div className="prop skin-prop">
                                <div className="prop-icon skin"/>
                                <div className="value">{props.vampire.skin.amount}</div>

                            </div>
                        </div>}
                    <div
                        className="rare">{props.vampire.cape.amount < 97.51 ? 'Rare breed bonus' : 'No breed bonus'}</div>
                </div>
            </Fragment>

    const onClick = (event) => {
        event.preventDefault()
        if (!props.onClick) return
        props.onClick(props.vampire)
    }

    const id = props.index >= 0 ? `card-${props.index}` : null

    return (
        <div className={classes.join(" ")}
             ref={props.draggable ? drag : null}
             id={id}
             onClick={onClick}>
            {content}
        </div>
    )

}

const mapStateToProps = (state) => ({
    isMatchWinner: state.play.isMatchWinner
})

export default connect(mapStateToProps)(Card)
