import React from 'react'

import './Button.scss'

const Button = (props) => {
    return (
        <div
            className={`button ${props.className}`}
            onClick={props.onClick}>
            <span>{props.children}</span>
        </div>
    )
}

export default Button
