export const PICK_CARD = 'PICK_CARD'
export const OPPONENT_PICKED_CARD = 'OPPONENT_PICKED_CARD'

export const CHECK_USERNAME = 'CHECK_USERNAME'

export const SHOW_COLLECTION = 'SHOW_COLLECTION'
export const SHOW_WELCOME = 'SHOW_WELCOME'

export const SHOW_NEW_DECK = 'SHOW_NEW_DECK'
export const HIDE_NEW_DECK = 'HIDE_NEW_DECK'
export const CHANGE_DECK_NAME = 'CHANGE_DECK_NAME'
export const RESET_NEW_DECK = 'RESET_NEW_DECK'
export const STORE_NEW_DECK = 'STORE_NEW_DECK'
export const STORE_TOTAL_PLAYERS_ONLINE = 'STORE_TOTAL_PLAYERS_ONLINE'

export const INSERT_IN_DECK = 'INSERT_IN_DECK'
export const REMOVE_CARD_FROM_DECK = 'REMOVE_CARD_FROM_DECK'
export const DELETE_DECK = 'DELETE_DECK'

export const SHOW_WIN_MESSAGE = 'SHOW_WIN_MESSAGE'
export const SHOW_LOST_MESSAGE = 'SHOW_LOST_MESSAGE'
export const HIDE_MESSAGES = 'HIDE_MESSAGES'

export const PRESTART_MATCH = 'PRESTART_MATCH'
export const START_MATCH = 'START_MATCH'
export const STOP_MATCH = 'STOP_MATCH'

export const STORE_GAME_DECK = 'STORE_GAME_DECK'
export const STORE_EXTENDED_DECK = 'STORE_EXTENDED_DECK'

export const WHEEL_VISIBILITY = 'WHEEL_VISIBILITY'
export const SPIN_WHEEL = 'SPIN_WHEEL'
export const SET_WHEEL_ITEM = 'SET_WHEEL_ITEM'
export const SET_WHEEL_ROTATION = 'SET_WHEEL_ROTATION'

export const SHOW_MATCH_RESULTS = 'SHOW_MATCH_RESULTS'

export const STORE_WHEEL_ITEMS = 'STORE_WHEEL_ITEMS'

export const SET_TIME_BETWEEN_MATCHES = 'SET_TIME_BETWEEN_MATCHES'
export const SET_AUTOMATIC_WHEEL_SPIN = 'SET_AUTOMATIC_WHEEL_SPIN'

export const SHOW_GAME_RESULTS = 'SHOW_GAME_RESULTS'
export const HIDE_GAME_RESULTS = 'HIDE_GAME_RESULTS'

export const DECREASE_OPPONENT_CARDS = 'DECREASE_OPPONENT_CARDS'

export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS'
export const SET_WALLET_DATA = 'SET_WALLET_DATA'
export const SET_USERNAME = 'SET_USERNAME'

export const SET_DECKS = 'SET_DECKS'
export const DECK_SAVED = 'DECK_SAVED'

export const SET_EXTENDED_DECK_ITEMS = 'SET_EXTENDED_DECK_ITEMS'

export const DISABLE_ROOM = 'DISABLE_ROOM'

export const SHOW_LEADERBOARD = 'SHOW_LEADERBOARD'
export const HIDE_LEADERBOARD = 'HIDE_LEADERBOARD'

export const HIDE_MY_COLLECTION_INTRO = 'HIDE_MY_COLLECTION_INTRO'
export const HIDE_DECK_MANAGER_INTRO = 'HIDE_DECK_MANAGER_INTRO'

export const SET_OPPONENT_USERNAME = 'SET_OPPONENT_USERNAME'