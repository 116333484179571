import React from 'react';
import {connect, useDispatch} from "react-redux";
import {useDrop} from "react-dnd";
import {insertInDeck} from "../store/actions";

const HandCard = (props) => {
    const dispatch = useDispatch()
    const {index} = props

    const [, drop] = useDrop(
        () => ({
            accept: 'card',
            drop: (item) => {
                dispatch(insertInDeck(item.vampire,index))
            },
            collect: (monitor) => ({
                canDrop: monitor.canDrop()
            })
        })
    )

    return (
        <>
            <div ref={drop}
                 className={`empty-hand-card ${props.hide && 'hide-empty-card'} card-${index+1}`}/>
        </>

    )
};

const mapStateToProps = (state) => ({
    newDeck: state.play.newDeck
})

export default connect(mapStateToProps)(HandCard)