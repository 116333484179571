import React, {useContext, useEffect, useState, useRef} from 'react'
import {connect, useDispatch} from 'react-redux'
import {motion} from 'framer-motion'
import {Steps} from 'intro.js-react'
import {Scrollbars} from 'react-custom-scrollbars-2'

import 'intro.js/introjs.css'

import Button from '../Button'
import Card from '../Card'
import Deck from './Deck'

import {SocketContext} from '../../context/socket'

import {deleteDeck, showNewDeck, storeGameDeck} from '../../store/actions'

import './MyCollection.scss'
import '../Button.scss'

const MyCollection = (props) => {

    const [page, setPage] = useState(null)
    const [items, setItems] = useState([])
    const [maxPage, setMaxPage] = useState(0)
    const [stepsEnabled, setStepsEnabled] = useState(!props.hideMyCollectionIntro)
    const socket = useContext(SocketContext)
    const stepsRef = useRef(null)

    const dispatch = useDispatch()

    const itemsInView = 10

    useEffect(() => {
        setMaxPage(Math.ceil(props.collection.length / itemsInView))
        setPage(0)
    }, [props.collection])

    useEffect(() => {
        setItems(props.collection.slice(page * itemsInView, page * itemsInView + itemsInView))
    }, [page, props.collection])


    const nextPage = () => {
        if (page === maxPage - 1) return
        setPage(page + 1)
    }

    const prevPage = () => {
        if (page === 0) return
        setPage(page - 1)
    }

    const setActiveDeck = (deck) => {
        dispatch(storeGameDeck(deck))
    }

    const handleDelete = (deck) => {
        if (props.deck === deck)
            dispatch(storeGameDeck(null))
        dispatch(deleteDeck(deck))
    }

    const startGame = () => {
        socket.emit('ready', props.deck.deck_id)
    }

    const walkthroughSteps = [
        {
            title: 'My Collection',
            element: '.collection',
            intro: 'Here is your entire collection of vampires.',
            position: 'right',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Decks',
            element: '.decks',
            intro: 'You can create different decks with the cards that you own. Once you created a deck, you can edit it by clicking twice on the deck name.',
            position: 'left',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Playing',
            element: '.play',
            intro: 'You can\'t play if you don\'t create a deck.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Card',
            element: '#card-0',
            intro: 'This is a vampire card.',
            position: 'right',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Attributes',
            element: '#card-0 .extended-props',
            intro: 'Each vampire card has specific attributes. The lower the attribute the higher the chance to win.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Stripes',
            element: '#card-0 .score',
            intro: 'This is the stripes value. A vampire with a small stripe value will give you the chance to spin the wheel.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Type',
            element: '#card-0 .title',
            intro: 'This is the vampire type.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Background',
            element: '#card-0 .bg-prop',
            intro: 'This is the background attribute.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Eyes',
            element: '#card-0 .eyes-prop',
            intro: 'This is the eyes attribute.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Clothes',
            element: '#card-0 .clothes-prop',
            intro: 'This is the clothes attribute.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Mouth',
            element: '#card-0 .mouth-prop',
            intro: 'This is the mouth attribute.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Weapon',
            element: '#card-0 .weapon-prop',
            intro: 'This is the weapon attribute.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Skin',
            element: '#card-0 .skin-prop',
            intro: 'This is the skin attribute.',
            tooltipClass: 'collection-tooltip'
        },
        {
            title: 'Rare breed bonus',
            element: '#card-0 .rare',
            intro: 'And this is the rare breed bonus for vampires with a cape rarer than 97.51%.',
            tooltipClass: 'collection-tooltip'
        }
    ]

    const options = {
        disableInteraction: true,
        doneLabel: 'Done',
        prevLabel: 'Previous',
        nextLabel: 'Next',
    }

    const handleStepsExit = () => {
        setStepsEnabled(false)
        handleIntroComplete()
    }

    const getLeaderboard = () => {
        socket.emit('get_leaderboard')
    }

    const onBeforeChangeStep = (nextStepIndex) => {
        if (nextStepIndex >= 2) {
            stepsRef.current.updateStepElement(nextStepIndex)
        }
    }

    const handleIntroComplete = () => {
        socket.emit('hide_intro', 'my_collection')
    }

    const handleOnClick = (e, deck) => {
        if (e.detail === 1) {
            setActiveDeck(deck)
        } else if (e.detail === 2) {
            socket.emit('get_extended_deck', deck.deck_id)
            dispatch(showNewDeck())
        }
    }

    return (
        <div id="my-collection">
            <Steps enabled={stepsEnabled}
                   initialStep={0}
                   onBeforeChange={onBeforeChangeStep}
                   steps={walkthroughSteps}
                   onExit={handleStepsExit}
                   options={options}
                   ref={stepsRef}
            />
            <div className="inner">
                <div className="cards-container">
                    <div className="collection">
                        <div className="collection-header">
                            <h2>collection</h2>
                            <div className="total-players">Players online: {props.totalPlayers}</div>
                            <div className="top-line"/>
                        </div>
                        <div className="cards">
                            {items.map((vampire, index) =>
                                <motion.div key={vampire.id} initial={{opacity: 0}}
                                            animate={{opacity: 1}}>
                                    <Card withZoom={true} vampire={vampire} index={index}/>
                                </motion.div>
                            )}
                        </div>
                        <div className="buttons">
                            <button
                                className={`nav-btn prev ${page === 0 ? 'disabled' : ''}`}
                                onClick={prevPage}/>
                            <button
                                className={`nav-btn next ${maxPage === 0 || page === maxPage - 1 ? 'disabled' : ''}`}
                                onClick={nextPage}/>
                            <Button className="simple leaderboard-btn" onClick={getLeaderboard}>Leaderboard</Button>
                        </div>
                    </div>
                </div>
                <div className="decks-container">
                    <h2>decks</h2>
                    <Scrollbars className="decks" autoHide={true} renderThumbVertical={({style, ...props}) =>
                        <div {...props} style={{
                            ...style,
                            right: '10px',
                            width: '4px',
                            background: 'rgba(0,0,0,.2)',
                            borderRadius: '2px'
                        }}/>
                    }>
                        {props.decks.length ? props.decks.map((deck, key) => (
                            <Deck name={deck.name}
                                  selected={props.deck === deck}
                                  onClick={(e) => handleOnClick(e, deck)}
                                  key={key}
                                  handleDelete={() => handleDelete(deck)}/>
                        )) : <div className="no-decks">You must create a deck before playing.</div>}
                        <Deck new={true}/>
                    </Scrollbars>
                    <button className={`play ${!props.deck ? 'disabled' : ''}`} onClick={startGame}>play</button>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    hideMyCollectionIntro: state.play.hideMyCollectionIntro,
    collection: state.play.collection,
    decks: state.play.decks,
    deck: state.play.deck,
    totalPlayers: state.play.totalPlayers
})

export default connect(mapStateToProps)(MyCollection)
