import React from 'react'
import socketIo from 'socket.io-client'

const isDevelopmentMode = () => {
    return '_self' in React.createElement('div')
}

const serverUrl = isDevelopmentMode() ?
    'http://localhost:4000' :
    'https://luna.vampiresofsol.com'

export const socket = socketIo.connect(serverUrl)
export const SocketContext = React.createContext()
