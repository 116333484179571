import React, {useContext} from 'react'
import {connect} from 'react-redux'
import Card from '../Card'

import {SocketContext} from '../../context/socket'
import {pickCard} from '../../store/actions'

import choose from '../../assets/sounds/choose.wav'

import './Match.scss'

const Match = (props) => {

    const socket = useContext(SocketContext)

    const chooseCardAudio = new Audio(choose)

    const cardsVar = {
        '--nb-cards': props.deck.items.length
    }

    const clockVar = {
        '--duration': `${props.matchTime}s`,
    }


    const handleCardPick = (vampire) => {
        chooseCardAudio.play()
        socket.emit("pick_card", JSON.stringify(vampire))
        props.dispatch(pickCard(vampire))
    }

    const getRoundNumber = () => {
        return props.deck.items.length === 5 ? '1' : props.deck.items.length === 4 ? '2' : '3'
    }

    return (
        <div className="match-container">
            <div className="match-inner">
                <div className="clock">
                    <div className="clock-inner">
                        <div className="clock-tick" style={clockVar}/>
                    </div>
                </div>
                <div className="current-player" style={cardsVar}>
                    {props.deck.items.map((vampire) =>
                        <Card
                            current={props.playingCard === vampire}
                            key={vampire.id}
                            vampire={vampire}
                            onClick={handleCardPick}
                            withHover={true}
                            extended={true}
                        />
                    )}
                </div>
                <div className="info">
                    <div className="round">Round {getRoundNumber()}/3</div>
                    <div className="info-text">Pick a card to play with.</div>
                    <div className="info-text">TIP: The highest rank in stripes starts the game.</div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    collection: state.play.collection,
    deck: state.play.extendedDeck,
    playingCard: state.play.playingCard,
    gameResults: state.play.gameResults,
    matchTime: state.play.matchTime,
})

export default connect(mapStateToProps)(Match)
