import {
    SHOW_WIN_MESSAGE,
    SHOW_LOST_MESSAGE,
    HIDE_MESSAGES
} from '../actions/actionTypes'

const initialState = {
    winIsVisible: false,
    lostIsVisible: false
}

const message = (state = initialState, action) => {

    const { type } = action

    switch(type) {
        case SHOW_WIN_MESSAGE:
            return {
                ...state,
                winIsVisible: true
            }
        case SHOW_LOST_MESSAGE:
            return {
                ...state,
                lostIsVisible: true
            }
        case HIDE_MESSAGES:
            return {
                ...state,
                winIsVisible: false,
                lostIsVisible: false
            }
        default:
            return state
    }

}

export default message
