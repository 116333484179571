import React from 'react'
import {connect, useDispatch} from 'react-redux'

import {showNewDeck} from '../../store/actions'

import './Deck.scss'

const Deck = (props) => {

    const dispatch = useDispatch()

    const classes = ['deck']

    if (props.new) classes.push('new')
    if (props.selected) classes.push('selected')

    const handleOnClick = (e) => {
        props.new && dispatch(showNewDeck())
        props.onClick && props.onClick(e)
    }

    return (
        <button className={classes.join(" ")} onClick={(e) => handleOnClick(e)}>
            {props.name ? <div className="deck-name">{props.name}</div> : 'new deck'}
            {/*{props.handleDelete && <div onClick={(e) => handleDelete(e)} className="delete-deck">delete</div>}*/}
        </button>
    )

}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Deck)
