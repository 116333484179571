import React, {useEffect, useState, useRef, useContext} from 'react'
import {connect, useDispatch} from 'react-redux'
import {motion} from 'framer-motion'

import {SocketContext} from '../../context/socket'

import {
    hideDeckManagerIntro,
    hideNewDeck,
    removeCardFromDeck, resetNewDeck, showCollection
} from '../../store/actions'

import HandCard from '../HandCard'
import Card from '../Card'

import './NewDeck.scss'
import {Steps} from "intro.js-react";

const NewDeck = (props) => {

    const dispatch = useDispatch()
    const [maxPage, setMaxPage] = useState(0)
    const [page, setPage] = useState(null)
    const [items, setItems] = useState([])
    const [deckName, setDeckName] = useState('')
    const [currentDeckItems, setCurrentDeckItems] = useState([])
    const [stepsEnabled, setStepsEnabled] = useState(!props.hideDeckManagerIntro)
    const stepsRef = useRef(null)
    const socket = useContext(SocketContext)
    const itemsInView = 12

    useEffect(() => {
        props.newDeck.name ? setDeckName(props.newDeck.name) : setDeckName(`Deck #${props.decks.length + 1}`)
    }, [props.newDeck.name])

    useEffect(() => {
        setMaxPage(Math.ceil(props.collection.length / itemsInView))
        setPage(0)
    }, [props.collection])

    useEffect(() => {
        setItems(props.collection.slice(page * itemsInView, page * itemsInView + itemsInView))
    }, [page, props.collection])

    const nextPage = () => {
        if (page === maxPage - 1) return
        setPage(page + 1)
    }

    const prevPage = () => {
        if (page === 0) return
        setPage(page - 1)
    }

    useEffect(() => {
        setCurrentDeckItems(props.newDeck.items)
    }, [props.newDeck.items])

    const handleRemoveCardFromDeck = (index) => {
        dispatch(removeCardFromDeck(index))
    }

    /**
     * Extract the ids from the deck
     *
     * @returns {*[]}
     */

    const extractIds = () => {

        const ids = []

        for (let i = 0; i < props.newDeck.items.length; i++) {
            ids.push(props.newDeck.items[i].id)
        }

        return ids

    }

    /**
     * Save the deck
     */

    const onSaveDeck = () => {

        if (props.newDeck.items.length !== 5)
            return alert('You need to add 5 cards to the deck.')

        if (!deckName)
            return alert('You need to name your deck.')

        socket.emit('save_deck', JSON.stringify({
            name: deckName,
            items: extractIds(),
            deck_id: props.newDeck.deck_id
        }))

    }

    /**
     * Store the deck name
     *
     * @param event
     */

    const onDeckNameChange = (event) => {
        setDeckName(event.target.value)
    }

    const getHandCards = () => {
        let content = []
        for (let i = 0; i < 5; i++) {
            content.push(<HandCard hide={props.newDeck.items[i]} index={i}/>)
        }
        return content
    }

    const handleBack = () => {
        dispatch(resetNewDeck())
        dispatch(hideNewDeck())
        dispatch(showCollection())
    }

    const variants = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.1
            }
        }
    }

    const cardVariants = {
        hidden: {opacity: 0, y: 20},
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        }
    }

    const walkthroughSteps = [
        {
            title:'Empty hand',
            element: '.empty-hand',
            intro: "This your empty hand. Any card you drag here will be part of your deck.",
            position: 'top',
            tooltipClass: 'deck-tooltip'
        },
        {
            title:'Add cards',
            element: '#card-0',
            intro: 'Drag the card into the hand to add it in the deck.',
            position: 'right',
            tooltipClass: 'deck-tooltip'
        },
        {
            title:'Deck name',
            element: '.deck-name',
            intro: 'You can change the deck name here.',
            position: 'bottom',
            tooltipClass: 'deck-tooltip'
        },
        {
            title:'Remove cards',
            element: '.empty-hand .card-3',
            intro: 'To remove a card from deck, simply click on it.',
            tooltipClass: 'deck-tooltip'
        },
        {
            title:'Save deck',
            element: '.deck-footer .base',
            intro: "Don't forget to save your deck!",
            tooltipClass: 'deck-tooltip'
        }
    ]

    const handleStepsExit = () => {
        setStepsEnabled(false)
        socket.emit('hide_intro', 'deck_manager')
        dispatch(hideDeckManagerIntro())
    }

    const onBeforeChangeStep = (nextStepIndex) => {
        if (nextStepIndex >= 1) {
            stepsRef.current.updateStepElement(nextStepIndex)
        }
    }

    const options = {
        disableInteraction: true,
        scrollToElement: false,
        doneLabel: 'Done',
        prevLabel: 'Previous',
        nextLabel: 'Next',
    }

    return (
        <div id="new-deck-container">
            <Steps enabled={stepsEnabled}
                   initialStep={0}
                   onBeforeChange={onBeforeChangeStep}
                   steps={walkthroughSteps}
                   onExit={handleStepsExit}
                   options={options}
                   ref={stepsRef}
            />
            <div className="new-deck-inner">
                <button
                    className={`nav-btn prev ${page === 0 ? 'disabled' : ''}`}
                    onClick={prevPage}/>
                <button
                    className={`nav-btn next ${maxPage === 0 || page === maxPage - 1 ? 'disabled' : ''}`}
                    onClick={nextPage}/>
                <div className="new-deck">
                    <input type="text" style={{width: `${deckName.toString().length * 21.3}px`}} className="deck-name"
                           value={deckName}
                           onChange={(e) => onDeckNameChange(e)}/>
                    <motion.div className="collection" variants={variants} initial="hidden" animate="show">
                        {items.map((vampire, index) => (
                            currentDeckItems.indexOf(vampire) === -1 &&
                            <motion.div variants={cardVariants} initial="hidden" animate="show">
                                <Card key={vampire.id} withZoom={true} index={index} draggable={true}
                                      vampire={vampire}/>
                            </motion.div>
                        ))}
                    </motion.div>
                    <div className="deck-footer">
                        <div className="button simple" onClick={handleBack}>Back</div>
                        <div className="button base" onClick={onSaveDeck}><span>Save deck</span></div>
                    </div>
                </div>
                <div className="empty-hand">
                    {getHandCards()}
                    {currentDeckItems.length && currentDeckItems.map((vampire, index) =>
                        vampire &&
                        <Card
                            className={`hand-card card-${index + 1}`}
                            key={vampire.id}
                            vampire={vampire}
                            onClick={() => handleRemoveCardFromDeck(index)}/>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => (
    {
        collection: state.play.collection,
        decks: state.play.decks,
        newDeck: state.play.newDeck,
        deck: state.play.deck,
        hideDeckManagerIntro: state.play.hideDeckManagerIntro
    }
)

export default connect(mapStateToProps)(NewDeck)
