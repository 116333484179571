import React, {useContext, useEffect, useState} from 'react'
import './Rooms.scss'
import {connect, useDispatch} from "react-redux";
import Button from "./Button";
import {showCollection} from "../store/actions";
import {SocketContext} from "../context/socket";

const Rooms = (props) => {

    const [dots, setDots] = useState('...')
    const [counter, setCounter] = useState(props.timeBeforeMatch)
    const socket = useContext(SocketContext)
    const dispatch = useDispatch()


    useEffect(() => {
        const interval = setInterval(() => {
            if (dots.length === 3) setDots('.')
            else setDots(dots + '.')
        }, 1000)
        return () => clearInterval(interval)
    })

    useEffect(() => {
        setCounter(props.timeBeforeMatch)
    }, [props.timeBeforeMatch]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (counter === 0) {
                setCounter(0)
                return clearInterval(interval)
            }
            setCounter(counter - 1)
        }, 1000)
        return () => clearInterval(interval)
    })

    const handleDisconnect = () => {
        dispatch(showCollection())
        socket.emit('disconnectFromQueue')
    }

    return (
        <div id="rooms">
            <div className="frame">
                <h1>Vampires of SOL</h1>
                {!props.prestartMatch ?
                    <div className="connecting">
                        <div className="text">Connecting to player<span>{dots}</span></div>
                        <Button className="simple" onClick={handleDisconnect}>Disconnect</Button>
                    </div>
                    :
                    <div className="starting">
                        Starting in&nbsp;<span>{counter}</span>s
                    </div>}
                <div className="footer">
                    &copy; Vampires of Sol. All Rights Reserved.
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    timeBeforeMatch: state.play.timeBeforeMatch,
    prestartMatch: state.play.prestartMatch
})

export default connect(mapStateToProps)(Rooms)
