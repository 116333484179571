import React from 'react'
import {connect} from 'react-redux'

import './MatchHistory.scss'
import Arena from "../Arena";

const MatchHistory = (props) => {

    const getItemLabel = (index) => {
        return props.wheelItems[index].name.toLowerCase()
    }
    const getWinnerMessage = () => {
        if (props.wheelItem === 6)
            return 'Garlic 🧄'
        else if (props.current.isMatchWinner && props.opponent.isMatchWinner)
            return "It's a draw"
        else if (props.current.isMatchWinner)
            return `${props.username} won`
        else return `${props.opponentUsername} won`
    }

    return (
        <div className="match-history">
            <div className="round">Round {props.round + 1}</div>
            <Arena withVs={true} singleAttribute={getItemLabel(props.wheelItem)} current={props.current}
                   opponent={props.opponent}/>
            <div className="win">
                {getWinnerMessage()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    wheelItems: state.play.wheelItems,
    gameResults: state.play.gameResults,
    username: state.play.username,
    opponentUsername: state.play.opponentUsername
})

export default connect(mapStateToProps)(MatchHistory)
