import React, {useMemo} from 'react'
import {createTheme, ThemeProvider} from '@material-ui/core'
import {clusterApiUrl} from '@solana/web3.js'

import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletWallet,
    getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets'
import {connect} from 'react-redux'

import {
    ConnectionProvider,
    WalletProvider
} from '@solana/wallet-adapter-react'

import {WalletDialogProvider} from '@solana/wallet-adapter-material-ui'

import Leaderboard from './Leaderboard/Leaderboard'
import SpinningWheel from './Match/SpinningWheel'
import MatchResults from './Match/MatchResults'
import GameResults from './Match/GameResults'
import Match from './Match/Match'
import Welcome from './Welcome/Welcome'
import Socket from './Socket'
import Rooms from './Rooms'

import MyCollection from "./MyCollection/MyCollection"
import NewDeck from "./MyCollection/NewDeck"

import {SocketContext, socket} from '../context/socket'

import "./Playground.scss"

const network = 'mainnet-beta'

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
})

const Playground = (props) => {

    const endpoint = useMemo(() => clusterApiUrl(network), [])

    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSlopeWallet(),
            getSolflareWallet(),
            getSolletWallet({network}),
            getSolletExtensionWallet({network})
        ],
        []
    )

    const renderPlayGround = () => (
        <div id="playground">
            {props.showWelcome && <Welcome/>}
            {props.showCollection && <MyCollection/>}
            {props.showLeaderboard && <Leaderboard/>}
            {props.showNewDeck && <NewDeck/>}
            {!props.startMatch && props.extendedDeck && !props.showCollection && <Rooms/>}
            <Socket/>
            {props.showGameResults ? <GameResults/> : ""}
            {props.showMatchResults ? <MatchResults/> : ""}
            {props.startMatch && !props.showMatchResults ? props.wheelVisible ? <SpinningWheel/> : <Match/> : ''}
        </div>
    )

    /**
     * Render the opponent cards
     *
     * @returns {*[]}
     */

    return (
        <ThemeProvider theme={theme}>
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                        <SocketContext.Provider value={socket}>
                            {renderPlayGround()}
                        </SocketContext.Provider>
                    </WalletDialogProvider>
                </WalletProvider>
            </ConnectionProvider>
        </ThemeProvider>
    )
}

const mapStateToProps = (state) => ({
    winIsVisible: state.message.winIsVisible,
    lostIsVisible: state.message.lostIsVisible,
    startMatch: state.play.startMatch,
    prestartMatch: state.play.prestartMatch,
    playingCard: state.play.playingCard,
    opponentCards: state.play.opponentCards,
    wheelVisible: state.play.wheelVisible,
    showMatchResults: state.play.showMatchResults,
    showGameResults: state.play.showGameResults,
    deck: state.play.deck,
    showCollection: state.play.showCollection,
    showNewDeck: state.play.showNewDeck,
    extendedDeck: state.play.extendedDeck,
    showLeaderboard: state.play.showLeaderboard,
    showWelcome: state.play.showWelcome
})

export default connect(mapStateToProps)(Playground)
