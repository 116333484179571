import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

/*import MatchHistory from './MatchHistory'
import Button from '../Button'*/
import Arena from '../Arena'

import {showGameResults} from '../../store/actions'

import lose from '../../assets/sounds/lose.wav'
import win from '../../assets/sounds/win.wav'

import './MatchResults.scss'

const MatchResults = (props) => {

    const [counter, setCounter] = useState(props.timeBetweenMatches)
    const [match, setMatch] = useState(null)

    useEffect(() => {
        if (props.isLastMatch) return
        if (props.timeBetweenMatches === 0) return
        const interval = setInterval(() => {
            if (counter === 0) {
                setCounter(0)
                return clearInterval(interval)
            }
            setCounter(counter - 1)
        }, 1000)
        return () => clearInterval(interval)
    })

    useEffect(() => {
        setMatch(props.gameResults[props.gameResults.length - 1])
    }, [props.gameResults])

    useEffect(() => {
        const winSound = new Audio(win)
        const loseSound = new Audio(lose)
        props.isMatchWinner ? winSound.play() : loseSound.play();
    }, [props.isMatchWinner])

    const handleGameResults = (event) => {
        props.dispatch(showGameResults())
    }

    const renderGarlicMessage = () => {
        return `Garlic takes away one experience point from ${props.canSpinTheWheel ? 'you' : 'your opponent'}.`
    }

    const getItemLabel = (index) => {
        return props.wheelItems[index].name.toLowerCase()
    }

    const renderWinLostMessage = () => {
        return `You've ${props.isMatchWinner ? 'won' : 'lost'} this round.`
    }

    const renderExperience = () => {
        if (props.wheelItem !== 6) {
            return props.isMatchWinner && !props.tie ? '+1XP' : '0XP'
        }
    }

    return (
        <div id="match-results">
            <div className="results-content">
                <div className="match-cards">
                    <Arena extended={true} isMatchWinner={props.isMatchWinner}
                           singleAttribute={getItemLabel(props.wheelItem)} withVs={true}/>
                </div>
                {match &&
                <div className="match-info">
                    <p className="round">Round {props.gameResults.length} results</p>
                    <p className="message">{props.wheelItem === 6 ? renderGarlicMessage() : props.tie ? 'It’s a draw! Better luck next time.' : renderWinLostMessage()}</p>
                    <p className="xp">{renderExperience()}</p>
                </div>}
                <div className="next-round" onClick={props.isLastMatch && handleGameResults}>
                    {!props.isLastMatch ? `Next round in ${counter}...` : 'Game Results'}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    playingCard: state.play.playingCard,
    opponentCard: state.play.opponentCard,
    canSpinTheWheel: state.play.canSpinTheWheel,
    wheelItem: state.play.wheelItem,
    wheelItems: state.play.wheelItems,
    isMatchWinner: state.play.isMatchWinner,
    timeBetweenMatches: state.play.timeBetweenMatches,
    isLastMatch: state.play.isLastMatch,
    gameResults: state.play.gameResults,
    tie: state.play.isTie
})

export default connect(mapStateToProps)(MatchResults)
