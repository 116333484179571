import {WalletDialogButton} from '@solana/wallet-adapter-material-ui'
import React, {useContext, useEffect, useState} from 'react'
import {useAnchorWallet} from '@solana/wallet-adapter-react'
import styled from 'styled-components'
import {connect, useDispatch} from 'react-redux'
import {motion, AnimatePresence} from "framer-motion";

import {SocketContext} from '../../context/socket'

import {checkUsername, setWalletAddress, showCollection} from '../../store/actions'

import Background from './Background'
import Button from '../Button'

import './Welcome.scss'

const ConnectButton = styled(WalletDialogButton)``

const Welcome = (props) => {

    const [username, setUsername] = useState(props.username ? props.username : '')
    const socket = useContext(SocketContext)
    const wallet = useAnchorWallet()
    const dispatch = useDispatch()

    useEffect(() => {

        if (wallet) {

            dispatch(setWalletAddress(wallet.publicKey.toString()))

            // No initial username
            if (props.username === false) {
                dispatch(checkUsername())
            }
        }

    }, [wallet, props.username, socket, dispatch])

    const onUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    const submitUsername = () => {
        if (username && username.length > 0) {
            socket.emit('set_username', username)
        }
    }

    const handleStartGame = () => {
        dispatch(showCollection())
    }

    const logoWithParagraph = props.collection && props.collection.length < 5

    return (
        <Background>
            <div id="welcome">
                {props.username ?
                    <motion.div initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0, transition: {delay: .2}}}
                                className="welcome-message">Hey {props.username}!
                    </motion.div>
                    :
                    <AnimatePresence>
                        <motion.div initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0}}
                                    className={`logo ${logoWithParagraph && 'logo-with-text'}`}>Vampires of SOL
                        </motion.div>
                    </AnimatePresence>
                }
                <div className="welcome-content">
                    <AnimatePresence>
                        <motion.div initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0}}>
                            {!wallet &&
                            <ConnectButton className="button fancy">Connect Wallet</ConnectButton>}
                        </motion.div>
                    </AnimatePresence>
                    {wallet && props.username === null &&
                    <div className="username-input">
                        <motion.div initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0}}>
                            <input type="text" placeholder="Enter username" value={username} maxLength={16}
                                   onChange={onUsernameChange}/>
                        </motion.div>
                        <AnimatePresence>
                            <motion.div initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}}
                                        exit={{opacity: 0}}>
                                <Button className="fancy"
                                        onClick={() => submitUsername()}>Done
                                </Button>
                            </motion.div>
                        </AnimatePresence>
                    </div>}
                    {wallet && props.checkingUsername && <div className="getting-data">Getting your data...</div>}
                    {wallet && props.username &&
                    <motion.div initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}} className="welcome-buttons">
                        {props.collection && props.collection.length >= 5 ?
                            <Button className="start fancy" onClick={handleStartGame}>Start Game</Button>
                            :
                            <div className="buy">
                                <p>A minimum of 5 cards are required to play.</p>
                                <a href="https://magiceden.io/marketplace?collection_symbol=vampires_of_sol"
                                   rel="noreferrer noopener"
                                   className="button outline"
                                   target="_blank">Buy on MagicEden</a>
                                <a href="https://digitaleyes.market/collections/Vampires%20Of%20SOL"
                                   className="button outline"
                                   rel="noreferrer noopener"
                                   target="_blank">Buy on DigitalEyes</a>
                                <a href="https://solsea.io/collection/615ee5176d3154c2fc6088dc"
                                   className="button outline"
                                   rel="noreferrer noopener"
                                   target="_blank">Buy on Solsea</a>
                            </div>
                        }
                    </motion.div>}
                </div>
                <div className="welcome-footer">
                    &copy; Vampires of Sol. All Rights Reserved.
                </div>
            </div>
        </Background>
    )

}

const mapStateToProps = (state) => (
    {
        checkingUsername: state.play.checkingUsername,
        username: state.play.username,
        collection: state.play.collection
    }
)

export default connect(mapStateToProps)(Welcome)
