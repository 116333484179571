import React from 'react'

import './Background.scss'

const Background = (props) => {
    return (
        <div id="welcome-background">
            {props.children}
        </div>
    )
}

export default Background
