import {
    SHOW_LOST_MESSAGE,
    SHOW_WIN_MESSAGE,
    PRESTART_MATCH,
    HIDE_MESSAGES,
    START_MATCH,
    STOP_MATCH,
    STORE_GAME_DECK,
    WHEEL_VISIBILITY,
    SPIN_WHEEL,
    SET_WHEEL_ITEM,
    PICK_CARD,
    OPPONENT_PICKED_CARD,
    SHOW_MATCH_RESULTS,
    STORE_WHEEL_ITEMS,
    SET_TIME_BETWEEN_MATCHES,
    SET_AUTOMATIC_WHEEL_SPIN,
    DECREASE_OPPONENT_CARDS,
    SHOW_GAME_RESULTS,
    SET_WHEEL_ROTATION,
    CHECK_USERNAME,
    SHOW_COLLECTION,
    SHOW_NEW_DECK,
    HIDE_NEW_DECK,
    INSERT_IN_DECK,
    REMOVE_CARD_FROM_DECK,
    CHANGE_DECK_NAME,
    SET_WALLET_DATA,
    SET_USERNAME,
    SET_DECKS,
    DECK_SAVED,
    DELETE_DECK,
    SET_WALLET_ADDRESS,
    STORE_EXTENDED_DECK,
    SET_EXTENDED_DECK_ITEMS,
    DISABLE_ROOM,
    SHOW_LEADERBOARD,
    HIDE_LEADERBOARD,
    HIDE_GAME_RESULTS,
    SHOW_WELCOME,
    HIDE_MY_COLLECTION_INTRO,
    HIDE_DECK_MANAGER_INTRO, RESET_NEW_DECK, STORE_NEW_DECK, STORE_TOTAL_PLAYERS_ONLINE, SET_OPPONENT_USERNAME
} from './actionTypes'

export const pickCard = (vampire) => ({type: PICK_CARD, vampire})
export const opponentPickedCard = (vampire) => ({type: OPPONENT_PICKED_CARD, vampire})
export const checkUsername = () => ({type: CHECK_USERNAME})

export const showWinMessage = () => ({type: SHOW_WIN_MESSAGE})
export const showLostMessage = () => ({type: SHOW_LOST_MESSAGE})
export const hideMessages = () => ({type: HIDE_MESSAGES})

export const showCollection = () => ({type: SHOW_COLLECTION})
export const showWelcome = () => ({type: SHOW_WELCOME})

export const deleteDeck = (deck) => ({type: DELETE_DECK, deck})

export const showNewDeck = () => ({type: SHOW_NEW_DECK})
export const hideNewDeck = () => ({type: HIDE_NEW_DECK})
export const resetNewDeck = () => ({type: RESET_NEW_DECK})
export const changeDeckName = (name) => ({type: CHANGE_DECK_NAME, name})
export const storeNewDeck = (deck) => ({type: STORE_NEW_DECK, deck})
export const storeTotalPlayersOnline = (players) => ({type: STORE_TOTAL_PLAYERS_ONLINE, players})

export const insertInDeck = (card, index) => ({type: INSERT_IN_DECK, card, index})
export const removeCardFromDeck = (index) => ({type: REMOVE_CARD_FROM_DECK, index})

export const prestartMatch = (timeBeforeMatch) => ({type: PRESTART_MATCH, timeBeforeMatch})
export const startMatch = (matchTime) => ({type: START_MATCH, matchTime})
export const stopMatch = () => ({type: STOP_MATCH})

export const storeGameDeck = (deck) => ({type: STORE_GAME_DECK, deck})
export const storeExtendedDeck = (deck) => ({type: STORE_EXTENDED_DECK, deck})

export const wheelIsVisible = (canSpinTheWheel = false, wheelTimeout) => ({
    type: WHEEL_VISIBILITY,
    wheelVisible: true,
    canSpinTheWheel,
    wheelTimeout
})
export const wheelIsNotVisible = () => ({type: WHEEL_VISIBILITY, wheelVisible: false})

export const setWheelItem = (wheelItem) => ({type: SET_WHEEL_ITEM, wheelItem})
export const setWheelRotation = (wheelRotation) => ({type: SET_WHEEL_ROTATION, wheelRotation})

export const spinWheel = (spinningTime) => ({type: SPIN_WHEEL, spinningTime})

export const showMatchResults = (isMatchWinner, isTie, isLastMatch, gameResults) => ({
    type: SHOW_MATCH_RESULTS,
    isMatchWinner,
    isTie,
    isLastMatch,
    gameResults
})

export const storeWheelItems = (wheelItems) => ({type: STORE_WHEEL_ITEMS, wheelItems})
export const setTimeBetweenMatches = (timeBetweenMatches) => ({type: SET_TIME_BETWEEN_MATCHES, timeBetweenMatches})
export const setAutomaticWheelSpin = (automaticWheelSpin) => ({type: SET_AUTOMATIC_WHEEL_SPIN, automaticWheelSpin})

export const showGameResults = () => ({type: SHOW_GAME_RESULTS})
export const hideGameResults = () => ({type: HIDE_GAME_RESULTS})

export const decreaseOpponentCards = () => ({type: DECREASE_OPPONENT_CARDS})

export const setWalletData = ({collection, decks, username}) => ({
    type: SET_WALLET_DATA,
    collection,
    decks,
    username
})

export const setWalletAddress = (walletAddress) => ({type: SET_WALLET_ADDRESS, walletAddress})

export const setUsername = (username) => ({type: SET_USERNAME, username})

export const setDecks = (decks) => ({type: SET_DECKS, decks})
export const deckSaved = () => ({type: DECK_SAVED})

export const setExtendedDeckItems = (extendedDeckItems) => ({type: SET_EXTENDED_DECK_ITEMS, extendedDeckItems})

export const disableRoom = () => ({type: DISABLE_ROOM})

export const showLeaderboard = (leaderboard = {}, positionInLeaderboard) => ({
    type: SHOW_LEADERBOARD,
    leaderboard,
    positionInLeaderboard
})

export const hideLeaderboard = () => ({type: HIDE_LEADERBOARD})

export const hideMyCollectionIntro = () => ({type: HIDE_MY_COLLECTION_INTRO})
export const hideDeckManagerIntro = () => ({type: HIDE_DECK_MANAGER_INTRO})

export const setOpponentUsername = (opponentUsername) => ({type: SET_OPPONENT_USERNAME, opponentUsername})